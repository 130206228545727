import { ApolloError } from '@apollo/client';
import { TargetStream } from 'hooks/use-video-call';
import { getSize } from 'lib/utils';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components/macro';
import { CrossIcon, VideoCallIcon, AlertIcon } from 'ui/icons';
import { Loader } from 'ui/loader';
import { VideoSessionButtons, VideoSessionInfo, Video } from './components';
import Plant from 'assets/images/plant.png';
import { TherapistPublicInformation } from 'features/find-specialist/specialist-details/query/__generated__/get-therapist-details-query';
import { TherapistPublicModel } from '__generated__/types';
import { format } from 'date-fns';
import { NoteModal } from 'features/common/note-modal';
import { truncate } from 'lodash';
import { NoteModalFormValues } from 'features/common/note-modal/note-modal';
import { AmplitudeEvents, amplitudeService } from 'lib/amplitude';
import { usePatientNoteAdd } from 'common/mutation/__generated__/patient-note-add';
import { usePatientNoteEdit } from 'common/mutation/__generated__/patient-note-edit';
import { patientNoteAddToCache } from 'lib/apollo-client/utils/patient-note-cache';
import { useGetPatientMeData } from 'common/query/__generated__/get-patient-me-data';
import { therapistNoteAddToCache } from 'lib/apollo-client/utils/therapist-note-cache';
import { useTherapistNoteAdd } from 'common/mutation/__generated__/therapist-note-add';

interface VideoWindowProps {
  handleStart: () => void;
  handleEnd: () => void;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  onCloseClick: () => void;
  isMicMuted: boolean;
  isCameraMuted: boolean;
  localStream?: MediaStream;
  targetStreams?: TargetStream[];
  isCallStarted: boolean;
  isPatient?: boolean;
  interlocutorName: string;
  sessionMetaError?: ApolloError;
  isSessionMetaLoading: boolean;
  hasLocalVideoRequest: boolean;
  isVisible: boolean;
  isConnectionError: string | null;
  therapistInfo?: Pick<TherapistPublicModel, 'avatarUrl' | 'fullName'>;
  sessionTimeStart?: string;
  isBothUserConnected?: boolean;
  isLostConnection?: boolean;
  dataForStartCall: any;
}

export enum VideoSize {
  small = 'small',
  middle = 'middle',
  large = 'large',
}

const HOVER_CLASS = 'isHovered';
let wrapperHoverTimeout: number | undefined;

const VideoWindow: FC<VideoWindowProps> = ({
  handleEnd,
  handleStart,
  localStream,
  targetStreams,
  isCallStarted,
  isPatient,
  interlocutorName,
  toggleMicrophone,
  toggleCamera,
  isMicMuted,
  isCameraMuted,
  sessionMetaError,
  isSessionMetaLoading,
  hasLocalVideoRequest,
  isVisible,
  onCloseClick,
  isConnectionError,
  therapistInfo,
  sessionTimeStart,
  isBothUserConnected,
  isLostConnection,
  dataForStartCall,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setIsSmallScreen(false);
    setVideoSize(VideoSize.middle);
  }, [isCallStarted]);

  const [therapistNoteAdd] = useTherapistNoteAdd();

  const [dateState, setDateState] = useState(
    format(new Date(), "hh:mmaaaaa'm'"),
  );

  useEffect(() => {
    setInterval(() => setDateState(format(new Date(), "hh:mmaaaaa'm'")), 30000);
  }, []);

  const { data: patientMeResponseData, loading: patientMeResponseLoading } =
    useGetPatientMeData();

  const patientName = dataForStartCall?.patientName;
  const sessionStartTime = format(
    new Date(dataForStartCall?.startDateUtc || 0),
    "hh:mmaaaaa'm'",
  );

  const patientMe = patientMeResponseData?.me?.patient;

  const connectedTherapistsOptions =
    patientMe?.connectedTherapists?.map(({ id, fullName }) => ({
      value: id,
      label: fullName,
    })) || [];

  const [isVideoSizeButtonsVisible, setVideoSizeButtonsVisible] =
    useState(false);
  const [videoSize, setVideoSize] = useState<VideoSize>(VideoSize.middle);
  const [isNoteShow, setIsNoteShow] = useState(false);

  const openNote = () => {
    setIsNoteShow(true);
  };

  const closeNote = () => {
    setIsNoteShow(false);
  };
  const isLobbyShow = !targetStreams?.length && !!isPatient && !isCallStarted;

  useEffect(() => {
    setIsNoteShow(false);
  }, [isLobbyShow]);

  const [patientNoteAdd] = usePatientNoteAdd();
  const [patientNoteEdit] = usePatientNoteEdit();

  const onAddNoteSubmit = ({
    date,
    text,
    id,
    isShared,
    shareWithTherapistId: therapistIds,
  }: NoteModalFormValues) => {
    const shareWithTherapistId =
      isShared === 'true' && therapistIds?.length !== 0 ? therapistIds : null;

    // TODO: to use event
    // if (shareWithTherapistId) {
    //   amplitudeService.logEvent('patient_note shared_with_therapist');
    // }

    if (isPatient) {
      if (id) {
        patientNoteEdit({
          variables: {
            input: { id, date, text, shareWithTherapistId },
          },
        });
      } else {
        patientNoteAdd({
          variables: {
            input: { date, text, shareWithTherapistId },
          },
          update: patientNoteAddToCache,
        });
        amplitudeService.logEvent(AmplitudeEvents.NOTE_ADDED);
      }
    } else {
      therapistNoteAdd({
        variables: {
          input: {
            date: new Date().toISOString().split('T')[0],
            text,
            createdForPatientId: dataForStartCall?.patientId,
          },
        },
        update: therapistNoteAddToCache,
      });
    }

    closeNote();
  };

  function handleCloseClick() {
    setIsSmallScreen(false);
    onCloseClick();
  }

  function handleWrapperMouseMove({
    currentTarget: wrapperElement,
  }: MouseEvent<HTMLDivElement>) {
    wrapperElement.classList.add(HOVER_CLASS);

    if (wrapperHoverTimeout) {
      clearTimeout(wrapperHoverTimeout);
    }

    wrapperHoverTimeout = setTimeout(
      () => wrapperElement.classList.remove(HOVER_CLASS),
      2000,
    );
  }

  if (!isVisible) return null;

  function renderContent() {
    if (sessionMetaError) {
      return <WarningText>{sessionMetaError.message}</WarningText>;
    } else if (isSessionMetaLoading || hasLocalVideoRequest) {
      return <Loader hasFillWholeBlock />;
    } else if (isConnectionError) {
      return (
        <WarningText>
          {isConnectionError}
          Try to reopen video call. If the problem remains please close all tabs
          and refresh the page
        </WarningText>
      );
    } else if (!localStream) {
      return (
        <WarningWrapper>
          <VideoCallIconStylized />
          <Warning>
            <WarningIcon>
              <AlertIcon />
            </WarningIcon>
            <WarningTitle>
              There was a problem accessing your microphone / video so the call
              could not connect. This might be due to your camera or mic still
              being used elsewhere (e.g. by Zoom, Skype, etc.). Please log out
              of other programs if necessary and allow access in your browser,
              then refresh the page.
            </WarningTitle>
          </Warning>
          <div>
            <SuggestionBlock>
              <SuggestionTitle>Suggestions</SuggestionTitle>
            </SuggestionBlock>
            <SuggestionBlock>
              <Suggestion>
                1. Check if you allowed access to mic and camera
              </Suggestion>
              <Suggestion>
                2. If access is granted, check if some other program is using
                mic and camera (e.g. Zoom, Skype, etc.)
              </Suggestion>
              <Suggestion>
                3. If another program is using it — turn off mic and camera
                there and refresh MTA page
              </Suggestion>
              <Suggestion>
                4. You can also sign out of that program / close the tab where
                camera and mic are being used.
              </Suggestion>
            </SuggestionBlock>
            <SuggestionBlock>
              <Suggestion>
                To see if another program is using mic and camera (for Windows
                OS)
              </Suggestion>
              <Suggestion>
                1. Go to browser Settings → Privacy → Camera → Allow desktop
                apps to access your camera and сhoose which Microsoft Store apps
                can access your camera
              </Suggestion>
              <Suggestion>
                2. You can also see there if you allowed access at all.
              </Suggestion>
            </SuggestionBlock>
            <Suggestion>
              Once the call has started, you have options to turn off the camera
              and mic at any time via the in-call buttons.
            </Suggestion>
          </div>
        </WarningWrapper>
      );
    }

    return (
      <Inner className="video-call__inner" $isSmallScreen={isSmallScreen}>
        {isCallStarted && (
          <VideoSessionInfo
            isSmallScreen={isSmallScreen}
            localStream={localStream}
            interlocutorName={interlocutorName}
            isPatient={isPatient}
            targetStreams={targetStreams}
            isNoteShow={isNoteShow}
            isLobbyShow={isLobbyShow}
          />
        )}

        <VideoWrapper $isSmallScreen={isSmallScreen}>
          {isCallStarted ? (
            targetStreams?.length ? (
              targetStreams?.map(({ stream, socketId }) => (
                <Video
                  id="started-video"
                  key={stream.id || socketId}
                  srcObject={stream}
                  isSmallScreen={isSmallScreen}
                  autoPlay
                  objectFit="contain"
                />
              ))
            ) : (
              <WaitingBlock>
                Please wait for your {isPatient ? 'therapist' : 'patient'} to
                join the session.
              </WaitingBlock>
            )
          ) : (
            localStream && (
              <Video
                srcObject={localStream}
                isSmallScreen={isSmallScreen}
                autoPlay
                muted
              />
            )
          )}
        </VideoWrapper>

        <VideoSessionButtons
          isSmallScreen={isSmallScreen}
          isCallStarted={isCallStarted}
          onToggleStartVideoChat={isCallStarted ? handleEnd : handleStart}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
          isMicMuted={isMicMuted}
          isCameraMuted={isCameraMuted}
          setVideoSizeButtonsVisible={setVideoSizeButtonsVisible}
          isVideoSizeButtonsVisible={isVideoSizeButtonsVisible}
          videoSize={videoSize}
          setVideoSize={setVideoSize}
          setIsSmallScreen={setIsSmallScreen}
          isVisible={isVisible}
          isLobbyShow={isLobbyShow}
          isPatient={isPatient}
          handleCloseClick={handleCloseClick}
          openNote={openNote}
          closeNote={closeNote}
          isNoteShow={isNoteShow}
          isBothUserConnected={isBothUserConnected}
          patientName={patientName}
          sessionStartTime={sessionStartTime}
          isLostConnection={isLostConnection}
        />
      </Inner>
    );
  }

  return (
    <Draggable
      disabled={!isSmallScreen}
      bounds="parent"
      position={!isSmallScreen ? { x: 0, y: 0 } : undefined}
    >
      <Background
        $isLobbyShow={isLobbyShow}
        $isSmallVideoSize={videoSize === VideoSize.small}
        $isNoteShow={isNoteShow}
      >
        <NoteModal
          isVisible={isNoteShow}
          onCloseNoteModal={closeNote}
          onAddNoteSubmit={onAddNoteSubmit}
          isNoteForVideoChart
          connectedTherapistsOptions={connectedTherapistsOptions}
          isTherapist={!isPatient}
        />
        <Wrapper
          $isSmallScreen={isSmallScreen}
          $isLobbyShow={isLobbyShow}
          $isNoteShow={isNoteShow}
          onMouseMove={handleWrapperMouseMove}
        >
          {renderContent()}
          {!isSmallScreen && !isLobbyShow && (
            <CurrentTime>{dateState}</CurrentTime>
          )}
          {!isSmallScreen && (
            <CloseButton
              $isSmallScreen={isSmallScreen}
              $isLobbyShow={isLobbyShow}
              onClick={handleCloseClick}
              className="video-call__close-button"
            >
              <CrossIconStylized />
            </CloseButton>
          )}
        </Wrapper>
        {isLobbyShow && !isSmallScreen && (
          <TherapistInfo>
            <Avatar src={therapistInfo?.avatarUrl} />
            <TherapistInfoText>
              Your{' '}
              <AccentText>
                {format(new Date(sessionTimeStart || 0), "hh:mmaaaaa'm'")}
              </AccentText>{' '}
              session with <br />
              <AccentText>{therapistInfo?.fullName}</AccentText> <br />
              will start soon
            </TherapistInfoText>
            <StandardSessionText>
              You can prepare by thinking about what you want to get from this
              session or perhaps by bringing your attention inwards and taking a
              moment to notice what&apos;s going on in your mind and body right
              now.
            </StandardSessionText>
          </TherapistInfo>
        )}

        {isPatient && !isNoteShow && !isSmallScreen && <PlantBackground />}
      </Background>
    </Draggable>
  );
};

const WarningText = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: ${getSize(359)};
  text-align: center;
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--purple4);
`;

const VideoCallIconStylized = styled(VideoCallIcon)`
  margin: 0 0 ${getSize(32)};
`;

interface BackgroundProps {
  $isLobbyShow?: boolean;
  $isSmallVideoSize: boolean;
  $isNoteShow?: boolean;
}

const Background = styled.section<BackgroundProps>`
  background: linear-gradient(180deg, #fcfcfa 0%, #f9f4ff 100%);
  position: absolute;
  width: ${({ $isSmallVideoSize }) => ($isSmallVideoSize ? '0' : '100%')};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: ${({ $isNoteShow }) =>
    $isNoteShow ? 'flex-start' : 'center'};
  z-index: 999;
`;

const PlantBackground = styled.span`
  background-image: url(${Plant});
  position: absolute;
  background-size: cover;
  width: 225px;
  height: 259px;
  bottom: -41px;
  right: -40px;
  z-index: 0;
`;

const Wrapper = styled.div<{
  $isSmallScreen?: boolean;
  $isLobbyShow: boolean;
  $isNoteShow?: boolean;
}>`
  position: ${({ $isLobbyShow, $isNoteShow }) =>
    $isLobbyShow || $isNoteShow ? 'relative' : 'fixed'};
  z-index: 102;
  background: white;
  box-shadow: 0px 10px 30px rgba(40, 31, 61, 0.06);
  border-radius: ${({ $isLobbyShow, $isNoteShow }) =>
    $isLobbyShow || $isNoteShow ? 10 : 0}px;
  width: ${({ $isLobbyShow }) => ($isLobbyShow ? '705px' : '100%')};
  height: ${({ $isLobbyShow, $isNoteShow }) =>
    $isLobbyShow ? '459px' : $isNoteShow ? 'calc(100vh - 300px)' : '100%'};
  display: flex;
  overflow: hidden;
  margin-right: ${({ $isNoteShow }) => ($isNoteShow ? '30px' : '0')};
  margin-top: ${({ $isNoteShow }) => ($isNoteShow ? '85px' : '0')};
  border-radius: 8px;

  &.${HOVER_CLASS} {
    .video-call__info,
    .video-call__close-button {
      opacity: 1;
      transform: translateY(
        ${({ $isSmallScreen }) => getSize($isSmallScreen ? 10 : 24)}
      );
    }

    .video-call__small-video {
      transform: translateY(
        ${({ $isSmallScreen, $isLobbyShow, $isNoteShow }) =>
          $isLobbyShow || $isNoteShow ? 0 : getSize($isSmallScreen ? 10 : 66)}
      );
    }

    ${({ $isSmallScreen }) =>
      !$isSmallScreen &&
      `
    .video-call__inner::before {
      opacity: 1;
    }
    `}

    .video-call__button {
      opacity: 1;
      transform: translateY(
        ${({ $isSmallScreen, $isLobbyShow }) =>
          $isLobbyShow ? getSize(-24) : getSize($isSmallScreen ? -10 : -24)}
      );
    }

    & .video-call__buttons {
      opacity: 1;
      transform: translateY(${getSize(-24)});
    }
  }

  & .video-call__buttons {
    position: ${({ $isNoteShow }) => ($isNoteShow ? 'fixed' : 'absolute')};
    ${({ $isLobbyShow, $isNoteShow }) =>
      $isLobbyShow || $isNoteShow
        ? `
      opacity: 1;
      transform: translateY(${getSize(-24)})
    `
        : ``};
  }

  ${({ $isLobbyShow, $isSmallScreen }) => {
    if ($isSmallScreen) {
      return `
        right: auto;
        top: auto;
        bottom: ${getSize($isLobbyShow ? 50 : 34)};
        left: ${getSize($isLobbyShow ? 50 : 26)};
        width: ${getSize(261)};
        height: ${getSize(185)};
      `;
    }
  }}
`;

const CurrentTime = styled.div`
  position: absolute;
  left: 32px;
  top: 32px;
  width: 86px;
  height: 34px;

  background: rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(249, 249, 249, 0.35);
  box-sizing: border-box;
  border-radius: 56px;

  font-style: normal;
  font-weight: 600;
  font-size: ${getSize(14)};
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  z-index: 9;
`;

const TherapistInfo = styled.div`
  width: 387px;
  height: 459px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(40, 31, 61, 0.06);
  border-radius: 10px;
  margin-left: 22px;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const TherapistInfoText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-top: 21px;
  text-align: center;
  user-select: none;
`;

const AccentText = styled.span`
  color: #6b4ee6;
`;

const StandardSessionText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #444752;
  margin-top: 30px;
  text-align: center;
  user-select: none;
`;

const Inner = styled.div<{ $isSmallScreen?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.4s ease-out;
    background: linear-gradient(
        180.36deg,
        var(--black5) 0.31%,
        var(--transparent) 17.91%
      ),
      linear-gradient(180deg, var(--transparent) 82.84%, var(--black5) 100%);
    opacity: 0;
  }

  ${({ $isSmallScreen }) => ($isSmallScreen ? 'cursor: move;' : '')}
`;

const VideoWrapper = styled.div<{ $isSmallScreen: boolean }>`
  height: 100%;
  background: #000000;
  ${({ $isSmallScreen }) =>
    $isSmallScreen &&
    `
  border-radius: ${getSize(8)};
  box-shadow: 0 0 ${getSize(20)} var(--gray4);
  overflow: hidden;
  `}
`;

const WaitingBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getSize(5)};
  height: 100%;
  font-weight: 500;
  font-size: ${getSize(18)};
  line-height: ${getSize(20)};
  color: var(--gray5);
`;

const CloseButton = styled.button<{
  $isSmallScreen: boolean;
  $isLobbyShow: boolean;
}>`
  position: ${({ $isLobbyShow }) => ($isLobbyShow ? 'fixed' : 'absolute')};
  top: 0;
  right: ${({ $isSmallScreen }) => getSize($isSmallScreen ? 15 : 20)};
  width: ${getSize(40)};
  height: ${getSize(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  background: var(--gray-opacity3);
  backdrop-filter: blur(${getSize(16)});
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const CrossIconStylized = styled(CrossIcon)`
  stroke: white;
`;

const WarningWrapper = styled.div`
  width: ${getSize(600)};
  margin: 0 auto ${getSize(50)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Warning = styled.div`
  background-color: var(--purple11);
  border-radius: 8px;
  padding: ${getSize(22)};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const WarningIcon = styled.div`
  min-width: 42px;
  height: 42px;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const WarningTitle = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-weight: 600;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--purple4);
`;

const SuggestionBlock = styled.div`
  margin-bottom: 16px;
`;
const Suggestion = styled.p`
  font-weight: 400;
  font-size: ${getSize(12)};
  line-height: ${getSize(20)};
  color: var(--purple4);
`;

const SuggestionTitle = styled(Suggestion)`
  font-weight: 600;
  font-size: ${getSize(14)};
`;

export default VideoWindow;
