import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

/** Icons */
import { PlayIcon } from 'ui';

/** Consts, types & helpers */
import { PATIENT_RESOURCES_ROUTES } from 'lib/routes';
import {
  DeviceType,
  EventType,
  ResourcesPostTypeEnum,
} from '__generated__/types';
import { TimeUtil } from 'lib/utils';
import { useAddEvent } from '../../../../../common/mutation/__generated__/add-event';

/** TODO replace content type with generated types */
interface ContentCardProps {
  type?: ResourcesPostTypeEnum.Video | ResourcesPostTypeEnum.Text;
  author?: string;
  title?: string;
  size: 'large' | 'small';
  id: number;
  imageUrl?: string;
  subtitle?: string;
  createdAt: string;
}

/**
 *
 * @description This is card element used to display video, text or audio preview.
 * @param size - size wil determine position of elements in card and show/hide of others
 * NOTE: Card element is ment to be used with bootstrap grid, so grid will affect final width.
 */
const ContentCard = (props: ContentCardProps) => {
  /** Init */
  const { id, title, size, author, imageUrl, type, subtitle, createdAt } =
    props;
  const history = useHistory();
  const [addOpenEvent] = useAddEvent({
    onError: (error) => console.log(error.message),
  });
  /** State Data */
  const [isHovered, setIsHovered] = useState(false);

  /**
   * @description If content has duration like video and audio.
   */
  const renderDuration = () => {
    return <DurationConteiner>10 min</DurationConteiner>;
  };

  /**
   * @description If content is vide it will render play badge.
   */
  const renderContentTypeBadge = () => {
    if (type === ResourcesPostTypeEnum.Text) {
      return null;
    }

    return (
      <ContentTypeBadge isHovered={isHovered} isCentered={size === 'small'}>
        <PlayIcon fill={isHovered ? '#6b4ee6' : 'white'} />
      </ContentTypeBadge>
    );
  };

  const renderNewBadge = () => {
    const now = new Date();
    const createdAtDate = new Date(parseInt(createdAt));
    const daysOld = TimeUtil.getDiffOfDays(createdAtDate, now);

    if (daysOld > 14) {
      return null;
    }

    return <NewBadge size={size}>NEW</NewBadge>;
  };

  /**
   * @method
   * @description Routing to preview for content.
   */
  const handleContentClick = () => {
    addOpenEvent({
      variables: {
        input: {
          eventType: EventType.ResourceOpened,
          metadata: {
            deviceType: DeviceType.Web,
            resourceId: id.toString(),
          },
        },
      },
    });
    history.push(
      PATIENT_RESOURCES_ROUTES.CONTENT_PREVIEW.pathBuild(id.toString()),
    );
  };

  return (
    <Wrapper>
      {renderNewBadge()}
      <BackgroundImg imageUrl={imageUrl}>
        <Container
          onClick={handleContentClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {size === 'large' && (
            <ContainerTitle size={size}>{title}</ContainerTitle>
          )}

          {renderContentTypeBadge()}
        </Container>
      </BackgroundImg>
      <Title size={size}>{size === 'large' ? subtitle : title}</Title>
      <Author>{author}</Author>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const BackgroundImg = styled.div<{ imageUrl: string | undefined }>`
  border-radius: 7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.imageUrl});
`;

const Title = styled.p<{ size: string }>`
  font-weight: ${(props) => (props.size === 'small' ? '700' : '400')};
  font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
  line-height: 160%;
`;

const ContainerTitle = styled.div<{ size: string }>`
  font-weight: 400;
  line-height: 160%;
  color: var(--white);
  font-size: ${(props) => (props.size === 'small' ? '18px' : '24px')};
`;

const Author = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
`;

const Container = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  cursor: pointer;
  position: relative;
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 25px;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    0deg,
    rgba(17, 23, 41, 0.4),
    rgba(17, 23, 41, 0.4)
  );
`;

const ContentTypeBadge = styled.div<{
  isHovered: boolean;
  isCentered: boolean;
}>`
  position: absolute;
  left: ${(props) => (props.isCentered ? '50%' : '25px')};
  top: ${(props) => (props.isCentered ? '50%' : '25px')};
  transform: ${(props) =>
    props.isCentered ? 'translate(-50%, -50%);' : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 42px;
  background: ${(props) =>
    props.isHovered ? 'var(--white)' : 'rgba(255, 255, 255, 0.3)'};
`;

const DurationConteiner = styled.div`
  position: absolute;
  bottom: 29px;
  right: 29px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: var(--white);
`;

const NewBadge = styled.div<{ size: string }>`
  background: var(--purple);
  position: absolute;
  top: ${(props) => (props.size === 'large' ? '25px' : '12px')};
  right: ${(props) => (props.size === 'large' ? '25px' : '12px')};
  padding: 5px 12px;
  color: var(--white);
  font-size: 13px;
  border-radius: 15px;
  z-index: 1;
`;

ContentCard.defaultProps = {
  size: 'small',
};

export default ContentCard;
