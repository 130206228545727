import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// Custom components
import { VideoContent } from './video-content';

// Queries
import { useGetPostDetails } from '../query/__generated__/get-post-details';

// Constants
import { ResourcesPostTypeEnum } from '__generated__/types';
import { TextContent } from './text-content';

/**
 *
 * @description Page will render post content. Content can be video, audio or plain html content.
 */
const ContentPreview = () => {
  /** Route params */
  const { postId } = useParams<{ postId: string }>();
  console.log('Zelimo ovaj post: ', postId);

  const { data: postData, loading } = useGetPostDetails({
    variables: {
      postId,
    },
  });
  const postDetails = postData?.getPostDetails;

  console.log('Post details area:');
  console.log(postDetails);

  if (!postDetails) {
    return null;
  }

  return (
    <Container>
      {postDetails?.type === ResourcesPostTypeEnum.Video ? (
        <VideoContent {...postDetails} />
      ) : (
        <TextContent {...postDetails} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: var(--white);
  min-height: 100%;
  padding-bottom: 20px;
`;

export default ContentPreview;
